<template>
  <div class="view pa24">
    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="dealerRuleForm"
          label-width="100px"
          class="ruleForm"
      >

        <el-form-item label="代理商名称" prop="dealerName" v-if="source == 'daili'">
          <el-input v-model="ruleForm.dealerName"></el-input>

        </el-form-item>
        <el-form-item label="经销商名称" prop="dealerName" v-else>
          <el-input v-model="ruleForm.dealerName"></el-input>
        </el-form-item>
        <el-form-item label="代号" prop="code">
          <el-input v-model="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="liaison">
          <el-input v-model="ruleForm.liaison"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <p style="color: red;margin-left: 100px;margin-top: -13px;margin-bottom: 10px;">注：手机号默认为系统账号，密码默认与账号相同</p>
        <el-form-item label="项目负责人" prop="leader">
          <el-input v-model="ruleForm.leader"></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="salesman">
          <el-input v-model="ruleForm.salesman"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="deptName">
          <el-input v-model="ruleForm.deptName"></el-input>
        </el-form-item>
        <el-form-item label="代理商地址" prop="address" v-if="source == 'daili'">
          <el-input v-model="ruleForm.address"></el-input>
          <!-- <Map ref="myMap" @updateLocation="updateLocation"/> -->
        </el-form-item>
        <el-form-item label="经销商地址" prop="address" v-else>
          <el-input v-model="ruleForm.address"></el-input>
          <!-- <Map ref="myMap" @updateLocation="updateLocation"/> -->
        </el-form-item>
        <!--动态表单 start-->
        <el-form-item :label="'销售区域'+ (index+1)" v-for="(item, index) in ruleForm.areaArr" :key="item.county"
                      :prop="'areaArr.' + index + '.data'"  :rules=" source == 'daili' ? [
                        { required: true, message: '请选择销售地区', trigger: 'blur' },
                      ] : []">
          <el-cascader size="large" :options="options" v-model="item.data" @change="handleChange(index, arguments)"
                       placeholder="请选择省市区" clearable :props="{ checkStrictly: true }"></el-cascader>
          <el-button class="ml10" @click="delItem(index)" v-if="index > 0">删除</el-button>
        </el-form-item>
        <el-button @click="addItem()" style="margin-left: 20px;">添加销售区域</el-button>
        <!--动态表单 end-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddDealer">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {fetchAddOrUpdateDeal} from '@/api/companyManage.js'
// import Map from './aliMap.vue'
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode
} from 'element-china-area-data'

export default {
  name: "productList",
  components: {
    // BaiduMap,
    // Map
  },
  props: [],

  data() {
    return {
      source: '',
      //测试
      provinces: [],
      dialogVisible: false,
      options: regionData,
      title: '添加经销商',
      ruleForm: {
        dealerName: '',
        code: '',
        type:null,
        liaison: '',
        phone: '',
        leader: '',
        salesman: '',
        deptName: '',
        address: '',
        coordinate: {
          lng: '',
          lat: ''
        },
        province: '',
        city: '',
        county: '',
        areaArr: [
          {
            province: '',
            city: '',
            county: '',
            data: [],
            address: ''
          }
        ]
      },
      rules: {
        dealerName: [
          {required: true, message: this.source=='daili'?'请输入代理商名称':'请输入经销商名称', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入代号', trigger: 'blur'},
        ],
        liaison: [
          {required: true, message: '请输入联系人名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
        ],
        // address: [
        //   {required: true, message: '请输入地址', trigger: 'blur'},
        // ]

      },
      mapZoom: 15,
      mapCenter: {lng: 0, lat: 0},
      selectedOptions: [],
      type: 'add',
      id: '',

    };
  },
  onShow() {

  },
  methods: {
    getNullArea() {
      let obj = {
        province: '',
        city: '',
        county: '',
        data: [],
        address: ''
      }
      return obj
    },
    addItem(index) {
      // this.ruleForm.areaArr.splice(index, 0, this.getNullArea())
      this.ruleForm.areaArr.push(this.getNullArea())
    },
    delItem(index) {
      this.ruleForm.areaArr.splice(index, 1)
    },

    updateLocation(Addr) {
      // console.log('Addr+++++++++++++++++++++++++', Addr)
      this.ruleForm.address = Addr.orgAddr
      // console.log('this.ruleForm.address', this.ruleForm.address)
      this.$refs.dealerRuleForm.validateField(["address"], (valid,vv) => {
        // console.log('------valid------',valid)
        // if(!valid){
        //   console.log("校验成功")
        // }
      });

      this.ruleForm.coordinate.lng = Addr.lon
      this.ruleForm.coordinate.lat = Addr.lat
      this.ruleForm.province = Addr.province
      this.ruleForm.city = Addr.city
      this.ruleForm.county = Addr.district
      document.querySelector('.search-box-wrapper input').value=this.ruleForm.address
    },
    openDialog(type, data, source) {
      this.source = source
      this.dialogVisible = true
      let v = this
      if (type === 'add') {
        if (source == 'daili') {
          this.title = '添加代理商'
          this.ruleForm.type = 1
        } else {
          this.title = '添加经销商'
          this.ruleForm.type = 2
        }
        this.type = 'add'
        this.id = ''
      } else {
        if (source == 'daili') {
          this.title = '编辑代理商'
        } else {
          this.title = '编辑经销商'
        }
        this.getData(data)
        this.type = "edit"
        this.id = data.id
        // this.$nextTick(() => {
        //   v.$refs.myMap.setData(this.ruleForm)
        // })
      }
    },
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
      this.ruleForm.areaArr = [
        {
          province: '',
          city: '',
          county: '',
          data: [],
          address: ''
        }
      ]
      this.$emit('getList')
    },
    resetForm() {
      this.$refs.dealerRuleForm.resetFields();
    },
    // 获取省市区
    getCity(lng, lat) {
      let vm = this
      return new Promise((resolve, reject) => {
        var geocoder = new AMap.Geocoder({
          radius: 100,
          extensions: "all",
        });
        geocoder.getAddress([lng, lat], function (status, result) {
          if (status === "complete" && result.info === "OK") {
            if (result && result.regeocode) {
              console.log("搜索获取地址的数据-----", result);
              vm.ruleForm.province = result.regeocode.addressComponent.province;
              vm.ruleForm.city = result.regeocode.addressComponent.city;
              vm.ruleForm.county = result.regeocode.addressComponent.district;
              resolve()
            }
          }
        })

      })

    },
    confirmAddDealer() {
      this.$refs.dealerRuleForm.validate((valid) => {
        if (valid) {
          // this.getCity(this.ruleForm.coordinate.lng, this.ruleForm.coordinate.lat).then(res => {
            let params = {
              dealerName: this.ruleForm.dealerName,
              code: this.ruleForm.code,
              type:this.ruleForm.type,
              liaison: this.ruleForm.liaison,
              phone: this.ruleForm.phone,
              address: this.ruleForm.address,
              leader: this.ruleForm.leader,
              salesman: this.ruleForm.salesman,
              deptName: this.ruleForm.deptName,
              // province: this.ruleForm.province,
              // city: this.ruleForm.city,
              // county: this.ruleForm.county,
              // lng: this.ruleForm.coordinate.lng,
              // lat: this.ruleForm.coordinate.lat,
              area: this.ruleForm.areaArr.map(item => {
                return {
                  province: item.province,
                  city: item.city,
                  county: item.county,
                  // data: [],
                  address: item.address
                }
              })
            }
            if (this.type === 'edit') {
              params.id = this.id
            }
            console.log('params', params)
            fetchAddOrUpdateDeal(params).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.closeDialog()
              }
            })

          // })

        }
      })
    },
    // 回显
    getData(data) {
      this.ruleForm.province = data.province ? data.province : ''
      this.ruleForm.city = data.city ? data.city : ''
      this.ruleForm.county = data.county ? data.county : ''
      this.ruleForm.areaArr = data.area.map(item => {
        let data = []
        if(item.province) {
          data.push(item.province)
          if(item.city) {
            data.push(item.city)
            if(item.county) {
              data.push(item.county)
            }
          }
        }
        return {
          ...item,
          data: data
        }
      })
      this.ruleForm.address = data.address
      this.ruleForm.dealerName = data.dealerName
      this.ruleForm.liaison = data.liaison
      this.ruleForm.phone = data.phone
      this.ruleForm.leader = data.leader
      this.ruleForm.salesman = data.salesman
      this.ruleForm.deptName = data.deptName
      this.ruleForm.coordinate.lng = data.lng
      this.ruleForm.coordinate.lat = data.lat
      this.ruleForm.code = data.code
      
      if (this.source == 'daili'){
        this.ruleForm.type = 1
      }else {
        this.ruleForm.type = 2
      }
      if(this.ruleForm.address) {
        var that = this
        setTimeout(function(){
          document.querySelector('.search-box-wrapper input').value=that.ruleForm.address
        },500)
      }
      
    },
    handleChange(index, e) {
      let value = e[0]
      this.ruleForm.areaArr[index].province = value[0] ? value[0] : ''
      this.ruleForm.areaArr[index].city = value[1] ? value[1] : ''
      this.ruleForm.areaArr[index].county = value[2] ? value[2] : ''
      let address = ''
      if(value[0]) {
        address+=CodeToText[value[0]]
        if(value[1]) {
          address+=CodeToText[value[1]]
          if(value[2]) {
            address+=CodeToText[value[2]]
          }
        }
      }
      this.ruleForm.areaArr[index].address = address
      // this.ruleForm.areaArr[index].address = CodeToText[(value[0] ? value[0] : '')] + CodeToText[(value[1]?value[1]:'')] + CodeToText[(value[2] ? value[2] : '')]
      console.log('采集到的数据', this.ruleForm.areaArr)
    },
    handlerBMap({BMap, map}) {
      this.BMap = BMap
      this.map = map
      if (this.ruleForm.coordinate && this.ruleForm.coordinate.lng) {
        this.mapCenter.lng = this.ruleForm.coordinate.lng
        this.mapCenter.lat = this.ruleForm.coordinate.lat
        this.mapZoom = 15
        map.addOverlay(new this.BMap.Marker(this.ruleForm.coordinate))
      } else {
        this.mapCenter.lng = 113.271429
        this.mapCenter.lat = 23.135336
        this.mapZoom = 10
      }
    },

    querySearch(queryString, cb) {
      var that = this
      var myGeo = new this.BMap.Geocoder()
      myGeo.getPoint(queryString, function (point) {
        if (point) {
          that.ruleForm.coordinate = point
          that.makerCenter(point)
        } else {
          that.ruleForm.coordinate = null
        }
      }, this.locationCity)
      var options = {
        onSearchComplete: function (results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确
            var s = []
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i)
              var item = {value: x.address + x.title, point: x.point}
              s.push(item)
              cb(s)
            }
          } else {
            cb()
          }
        }
      }
      var local = new this.BMap.LocalSearch(this.map, options)
      local.search(queryString)
    },
    handleSelect(item) {
      var {point} = item
      this.ruleForm.coordinate = point
      this.makerCenter(point)
    },
    makerCenter(point) {
      if (this.map) {
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
        this.mapCenter.lng = point.lng
        this.mapCenter.lat = point.lat
        this.mapZoom = 15
      }
    }
  },


};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
</style>
